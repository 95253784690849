import { useGlobals }             from '@/hooks/use-globals';
import { UserNotificationsPayload } from '@/store/modules/user/user.store';

export interface ChangeUserPasswordPayload {
    userUuid: string,
    password: string,
    repeatPassword: string
}


export interface ChangeUserNamePayload {
    userUuid: string;
    name: string;
    lastName: string;
}

interface UseUser {
    changePassword: (payload: ChangeUserPasswordPayload) => Promise<void>
    changeLanguage: (payload: {userUuid: string, language: string}) => Promise<void>
    changeUserName: (payload: ChangeUserNamePayload) => Promise<void>
    changeUserNotificationsSettings: (payload: UserNotificationsPayload) => Promise<void>
}

export const useUser = (): UseUser => {
  const { store } = useGlobals()

  const changePassword = async (payload: ChangeUserPasswordPayload) => await store.dispatch('user/changeUserPassword', payload)
  const changeUserName = async (payload: ChangeUserNamePayload) => await store.dispatch('user/editUserName', payload)
  const changeUserNotificationsSettings = async (payload: UserNotificationsPayload) => await store.dispatch('user/editUserNotification', payload)
  const changeLanguage = async (payload: {userUuid: string, language: string}) => await store.dispatch('user/changeLanguage', payload)

  return {
    changeLanguage,
    changePassword,
    changeUserName,
    changeUserNotificationsSettings
  }
}
