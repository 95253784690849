import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_user_profile_template = _resolveComponent("c-user-profile-template")

  return (_openBlock(), _createBlock(_component_c_user_profile_template, {
    "button-label": _ctx.useTranslations('buttons.changePassword'),
    "section-title": _ctx.useTranslations('title.changePassword'),
    onChangeUserData: _ctx.changeUserPassword
  }, {
    default: _withCtx(() => [
      _createVNode(_component_c_form_input, {
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.password = $event)),
        "dynamic-error-parameters": [6],
        error: _ctx.validator.password.$error ? 'passwordIsTooShort' : null,
        label: _ctx.useTranslations('labels.password'),
        type: "password"
      }, null, 8, ["modelValue", "error", "label"]),
      _createVNode(_component_c_form_input, {
        modelValue: _ctx.repeatPassword,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.repeatPassword = $event)),
        error: _ctx.validator.repeatPassword.$error ? 'notMatchToPassword' : null,
        label: _ctx.useTranslations('labels.repeatPassword'),
        type: "password"
      }, null, 8, ["modelValue", "error", "label"])
    ]),
    _: 1
  }, 8, ["button-label", "section-title", "onChangeUserData"]))
}