import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_radio_group = _resolveComponent("c-form-radio-group")
  const _component_c_user_profile_template = _resolveComponent("c-user-profile-template")

  return (_openBlock(), _createBlock(_component_c_user_profile_template, {
    "button-label": _ctx.useTranslations('buttons.changeLanguage'),
    "section-title": _ctx.useTranslations('title.changeLanguage'),
    onChangeUserData: _ctx.changeLang
  }, {
    default: _withCtx(() => [
      _createVNode(_component_c_form_radio_group, {
        modelValue: _ctx.formValues,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formValues = $event)),
        group: _ctx.languages,
        label: _ctx.useTranslations('lang'),
        onClick: _ctx.setNewLang
      }, null, 8, ["modelValue", "group", "label", "onClick"])
    ]),
    _: 1
  }, 8, ["button-label", "section-title", "onChangeUserData"]))
}