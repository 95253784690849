
import { computed, defineComponent, reactive, toRefs } from 'vue';
import CFormInput                                      from '@/components/common/form/form-input/form-input.component.vue';
import CUserProfileTemplate                            from '@/views/profile/components/user-profile-template/user-profile-template.vue';
import { ChangeUserNamePayload, useUser }              from '@/hooks/use-user';
import { useTranslations }                             from '@/hooks/use-translations';
import { useGlobals }                                  from '@/hooks/use-globals';
import useVuelidate                                    from '@vuelidate/core';
import { required }                                    from '@vuelidate/validators';
import { Notification }                                  from '@/components/notification/notification';
import i18n                                            from '@/i18n';
import { GLOBAL }                                        from '@/helpers/variables';

export default defineComponent({
  name: 'CNameSection',
  components: { CUserProfileTemplate, CFormInput },
  props: {
    userUuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { changeUserName } = useUser()
    const { store } = useGlobals()
    const formValues = reactive(store.getters['user/userName'])
    const rules = computed(() => ({
      name: { required },
      lastName: { required }
    }))

    const validator = useVuelidate(rules, formValues)

    const changeUserData = () => {
      validator.value.$touch()
      !validator.value.$error
        ? changeUserNameAndResetValidator({ userUuid: props.userUuid, ...formValues })
        : Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
    }

    const changeUserNameAndResetValidator = (payload: ChangeUserNamePayload) => {
      changeUserName(payload)
      validator.value.$reset()
    }
    return {
      ...toRefs(formValues),
      useTranslations,
      changeUserData,
      validator
    }
  }

})
