
import { computed, defineComponent, reactive, toRefs } from 'vue';
import CFormInput                                      from '@/components/common/form/form-input/form-input.component.vue';
import CUserProfileTemplate                            from '@/views/profile/components/user-profile-template/user-profile-template.vue';
import { ChangeUserPasswordPayload, useUser }          from '@/hooks/use-user';
import useVuelidate                                    from '@vuelidate/core';
import { minLength, required, sameAs }                 from '@vuelidate/validators';
import { useTranslations }                             from '@/hooks/use-translations';
import { Notification }                                  from '@/components/notification/notification';
import i18n                                            from '@/i18n';
import { GLOBAL }                                        from '@/helpers/variables';

export default defineComponent({
  name: 'CPasswordSection',
  components: { CUserProfileTemplate, CFormInput },
  props: {
    userUuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { changePassword } = useUser()
    const formState = reactive({
      password: '',
      repeatPassword: ''
    })

    const rules = computed(() => ({
      password: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        sameAs: sameAs(formState.password)
      }
    }))

    const validator = useVuelidate(rules, formState)

    const changeUserPassword = (): void => {
      validator.value.$touch();
      !validator.value.$error
        ? changeUserPasswordAndResetFormValues({ userUuid: props.userUuid, ...formState })
        : Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
    }

    const changeUserPasswordAndResetFormValues = (payload: ChangeUserPasswordPayload) => {
      changePassword(payload);
      resetFormState()
    }

    const resetFormState = () => {
      formState.password = ''
      formState.repeatPassword = ''
      validator.value.$reset()
    }


    return {
      changeUserPassword,
      useTranslations,
      ...toRefs(formState),
      validator
    }
  }



})
