
import CMainContent          from '@/components/main-content/main-content.component.vue';
import { useTranslations }   from '@/hooks/use-translations'
import CNameSection          from './components/name-section/name-section.component.vue';
import CPasswordSection      from './components/password-section/password-section.component.vue';
import CLanguageSection      from './components/language-section/language-section.component.vue';
import { useGlobals }        from '@/hooks/use-globals';
import CNotificationsSection from '@/views/profile/components/notifiacations-section/notifications-section.component.vue';
export default {
  components: { CLanguageSection, CNotificationsSection, CPasswordSection, CNameSection, CMainContent },
  setup() {
    const { store } = useGlobals()
    const userUuid = store.getters['user/getUserUuid']

    return {
      userUuid,
      useTranslations
    }
  }
}
