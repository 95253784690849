
import CButton from '@/components/common/button/button.component.vue';
export default {
  name: 'CUserProfileTemplate',
  components: { CButton },
  props: {
    button: {
      type: Boolean,
      default: true,
    },
    buttonLabel: {
      type: String,
      default: 'Change'
    },
    sectionTitle: {
      type: String,
      default: 'Change'
    }
  },
  emits:['change-user-data']
}
