
import { computed, defineComponent, reactive, toRefs } from 'vue';
import CUserProfileTemplate                            from '@/views/profile/components/user-profile-template/user-profile-template.vue';
import { useUser }          from '@/hooks/use-user';
import { useTranslations }                             from '@/hooks/use-translations';
import i18n                                            from '@/i18n';
import CFormRadioGroup from '@/components/common/form/form-radio-group/form-radio-group.component.vue';
import { useGlobals } from '@/hooks/use-globals';

export default defineComponent({
  name: 'CLanguageSection',
  components: { CUserProfileTemplate, CFormRadioGroup },
  props: {
    userUuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { changeLanguage } = useUser()
    const { store } = useGlobals()
    const formValues = computed(() => store.getters['user/getUserLanguage'])

    const setNewLang = (e: InputEvent) => {
      store.commit('user/setUserLanguage', (e.target as HTMLInputElement).value)
    }

    const languages = [{ name: 'PL', value: 'PL' }, { name: 'EN', value: 'EN' },{ name: 'UK', value: 'UK' }]

    const changeLang = async () => {
      await changeLanguage({ userUuid: props.userUuid, language: formValues.value })
      await location.reload()
    }

    return {
      changeLang,
      setNewLang,
      formValues,
      languages,
      useTranslations,
    }
  }



})
