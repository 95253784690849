import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_user_profile_template = _resolveComponent("c-user-profile-template")

  return (_openBlock(), _createBlock(_component_c_user_profile_template, {
    "button-label": _ctx.useTranslations('buttons.changeName'),
    "section-title": _ctx.useTranslations('title.changeName'),
    onChangeUserData: _ctx.changeUserData
  }, {
    default: _withCtx(() => [
      _createVNode(_component_c_form_input, {
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.name = $event)),
        error: _ctx.validator.name.$error ? 'required' : null,
        label: _ctx.useTranslations('labels.name')
      }, null, 8, ["modelValue", "error", "label"]),
      _createVNode(_component_c_form_input, {
        modelValue: _ctx.lastName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.lastName = $event)),
        error: _ctx.validator.lastName.$error ? 'required' : null,
        label: _ctx.useTranslations('labels.lastName')
      }, null, 8, ["modelValue", "error", "label"])
    ]),
    _: 1
  }, 8, ["button-label", "section-title", "onChangeUserData"]))
}