
import { computed, defineComponent, reactive, toRefs, ref } from 'vue';
import CUserProfileTemplate from '@/views/profile/components/user-profile-template/user-profile-template.vue';
import CCustomSwitch from '@/components/common/custom-switch/custom-switch.component.vue';
import CFormInput from '@/components/common/form/form-input/form-input.component.vue';
import { switchTypes } from '@/types';
import { required, helpers } from '@vuelidate/validators';
import { useTranslations } from '@/hooks/use-translations';
import { UserNotificationsResponse } from '@/store/modules/user/user.store';
import { useUser } from '@/hooks/use-user';
import { useGlobals } from '@/hooks/use-globals';
import useVuelidate from '@vuelidate/core';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';

export default defineComponent({
  name: 'CNotificationsSection',
  components: { CCustomSwitch, CUserProfileTemplate, CFormInput },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const phoneError = ref(false);
    const { changeUserNotificationsSettings } = useUser();
    const { store } = useGlobals();
    const switchesState: UserNotificationsResponse = reactive(
      store.getters['user/userNotificationsSettings']
    );

    const phoneRegex = new RegExp(/^\+(?:[0-9] ?){10}[0-9]$/);
    const isValidPhoneNumber = computed(() => {
      return phoneRegex.test(switchesState.phoneNumber);
    });

    const rules = computed(() => ({
      phoneNumber: {
        required,
        regex: helpers.regex(phoneRegex),
      },
    }));

    const validator = useVuelidate(rules, switchesState);

    const updateUserNotificationsSettings = (): void => {
      changeUserNotificationsSettings({
        userUuid: props.userUuid,
        ...switchesState,
      });
    };

    const changeUserData = () => {
      if (switchesState.smsNotificationsEnabled) {
        validator.value.$touch();

        if (!validator.value.$error) {
          updateUserNotificationsSettings();

          phoneError.value = false;
        } else {
          Notification.warning(
            i18n.global.t(`${GLOBAL}.warning.phone`),
            i18n.global.t(`${GLOBAL}.warning.phone_dial`)
          );
          phoneError.value = true;
        }
      } else {
        updateUserNotificationsSettings();

        phoneError.value = false;
      }
    };

    return {
      changeUserData,
      ...toRefs(switchesState),
      updateUserNotificationsSettings,
      switchesState,
      switchTypes,
      validator,
      useTranslations,
      isValidPhoneNumber,
      phoneError,
    };
  },
});
